define("client-base/components/cb-table/cb-row/component", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "client-base/utils/error-handler"], function (_exports, _component, _tracking, _object, _errorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * @class CbTableRowComponent
   * @param row
   * @param indexRow
   * @param actionSelectRow
   * @param actionDragRow
   * @param actionDropRow
   * @param selectedRow
   * @param actionSelect
   * @param cntFixedColumns
   * @param setDraggedRow
   * @param draggedRow
   * 
   */
  let CbTableRowComponent = (_class = class CbTableRowComponent extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "errorHandler", new _errorHandler.default());

      _initializerDefineProperty(this, "cntColumn", _descriptor, this);
    }

    get indexRow() {
      return this.args.indexRow ?? 0;
    }

    get cntFixedColumns() {
      return this.args.cntFixedColumns ?? 0;
    }

    /** 
     * we use the first cell to implement some special feature for the entire row
     * TODO implement something like a RowInfo
     */
    get firstRow() {
      return this.args.row?.firstObject;
    }

    get cells() {
      return this.args.row?.slice(this.cntFixedColumns, this.args.row.length);
    }
    /**
     * to use the show / hide feature the value of the first cell in the row must have an attribute hide
     * the differenziation between show and hide comes from historical reasons, when there was no ?? operator
     */


    get show() {
      return !this.firstRow?.value.hide ?? false;
    }

    get draggable() {
      return this.firstRow?.draggableRow;
    }

    selectRow() {
      this.args.actionSelectRow?.(this.indexRow, this.args.row);
    }

    dragStart() {
      this.args.setDraggedRow?.(this.args.row);
    }

    dragOver(event) {
      if (this.args.actionDragRow?.(this.args.row)) {
        event.preventDefault();
      }
    }

    dragEnd() {
      this.args.actionDragRow?.();
      this.args.setDraggedRow?.(null);
    }

    drop() {
      this.args.actionDropRow?.(this.args.row, this.args.draggedRow, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "cntColumn", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "selectRow", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectRow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragStart", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "dragStart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragOver", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "dragOver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragEnd", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "dragEnd"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "drop", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "drop"), _class.prototype)), _class);
  _exports.default = CbTableRowComponent;
});