define("client-base/components/cb-table/cb-link-cell/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * For some unknown reason you can't invoke this component without this class. Other components like
   * CbLoadingIcon works, maybe the cause is that this component is nested under CbTable?
   * 
   * @class CbTableLinkCellComponent
   * 
   */
  // remove this line and the comment above if this class is not empty anymore
  // eslint-disable-next-line ember/no-empty-glimmer-component-classes
  class CbTableLinkCellComponent extends _component.default {}

  _exports.default = CbTableLinkCellComponent;
});