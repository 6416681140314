define("client-base/utils/math-ext", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.digits = digits;
  _exports.geometricMean = geometricMean;
  _exports.geometricVariance = geometricVariance;
  _exports.round = round;

  /**
   * round a value to a given precision
   * Why javascript does not provide such a function?
   *
   * TODO What if value is a string but contains a valid number?
   *
   * @param value the value to round
   * @param precision number of decimal places
   * @returns the rounded value if the value is a number otherwise the unmodified value
   */
  function round(value, precision) {
    if (typeof value === 'number') {
      let c = Math.pow(10, precision ?? 0);
      return Math.round(value * c) / c;
    }

    return value;
  }
  /**
   * calculate the geometric mean using the logarithmic way
   * https://en.wikipedia.org/wiki/Geometric_mean#Relationship_with_logarithms
   *
   * the regular algorithm (n'th root of the product) produces an overflow for n > ~200
   * the regular algorithm is faster, but it is only measurable for n > ~ 10000
   *
   * @param values an array of number
   * @returns the geometric mean of the values
   */


  function geometricMean(values) {
    if (values.length === 1) return values[0];
    if (values.length > 0) return Math.pow(2, values.reduce((sum, value) => sum + Math.log2(value), 0) / values.length);
  }
  /**
   * TODO the result seems to be wrong
   * calculate the variance using the geometric mean
   * 
   * @param {number[]} values an array of numbers
   * @returns the variance calculated with the geometric mean
   */


  function geometricVariance(values) {
    let mean = geometricMean(values);
    return geometricMean(values.map(value => (value - mean) ** 2));
  }
  /*
   * get the number of digits before the comma
   *
   * this method is not defined for negative numbers, because it is not needed up to now, and so there is no test case
   * Implement functionalaty when you need it.
   * 
   * @return 
   * 		0 if value === -Infinity special case if this method is called this way: digits(...Math.max(empty array))
   * 		1 if 0 <= value < 1 only take the digits before the comma
   * 		number of digits
   * @throws error if value < 0 && value !== -Infinity
   */


  function digits(value) {
    if (value === -Infinity) return 0;else if (value < 0) throw new Error('This function is not defined for values below 0, you gave me: ' + value);else if (value < 1) return 1;else return Math.log(value) * Math.LOG10E + 1 | 0;
  }
});