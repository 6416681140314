define("client-base/components/cb-list/list-item/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <li class = {{concat "item"
  				(if this.selected " selected")
  				(if this.second " second")
  		}}
  		role = "button"
  		{{on "click" this.click}}>
  	{{yield this.selected this.second}}
  </li>
  
  */
  {
    "id": "u2KFQhcy",
    "block": "[[[11,\"li\"],[16,0,[28,[37,0],[\"item\",[52,[30,0,[\"selected\"]],\" selected\"],[52,[30,0,[\"second\"]],\" second\"]],null]],[24,\"role\",\"button\"],[4,[38,2],[\"click\",[30,0,[\"click\"]]],null],[12],[1,\"\\n\\t\"],[18,1,[[30,0,[\"selected\"]],[30,0,[\"second\"]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"concat\",\"if\",\"on\",\"yield\"]]",
    "moduleName": "client-base/components/cb-list/list-item/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});