define("client-base/components/cb-environment/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.show}}
  	{{#if (has-block)}}
  		{{yield}}
  	{{else}}
  		{{this.environment}}
  	{{/if}}
  {{/if}}
  */
  {
    "id": "oo3ryHbf",
    "block": "[[[41,[30,0,[\"show\"]],[[[41,[48,[30,1]],[[[1,\"\\t\\t\"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"\\t\\t\"],[1,[30,0,[\"environment\"]]],[1,\"\\n\"]],[]]]],[]],null]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "client-base/components/cb-environment/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});