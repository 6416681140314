define("client-base/components/cb-splitter/component", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/utils"], function (_exports, _component, _tracking, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _class3, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DragProperties = (_class = class DragProperties {
    constructor() {
      _initializerDefineProperty(this, "offset", _descriptor, this);

      _initializerDefineProperty(this, "szComplete", _descriptor2, this);
    }

    setData(offset, szComplete) {
      this.offset = offset;
      this.szComplete = szComplete;
    }

    clearData() {
      this.offset = null;
      this.szComplete = null;
    }

    getDragPosition(posX) {
      let szElementLeft = this.offset + posX;
      if (szElementLeft < 0) szElementLeft = 0;else if (szElementLeft > this.szComplete) szElementLeft = this.szComplete;
      return szElementLeft / this.szComplete * 100;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "offset", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "szComplete", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class);
  /**
   * Provides a splitter betweeen two elements. With this splitter the width of the
   * elements can be changed.
   * <br>
   * @class CbSpliiterComponent
   * @param {string} selector1
   * @param {string} selector2
   */

  let CbSplitterComponent = (_class3 = class CbSplitterComponent extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "selectorSplitter", "splitter");

      _initializerDefineProperty(this, "szSplitter", _descriptor3, this);

      _initializerDefineProperty(this, "lstElement1", _descriptor4, this);

      _initializerDefineProperty(this, "lstElement2", _descriptor5, this);

      _initializerDefineProperty(this, "elSplitter", _descriptor6, this);

      _initializerDefineProperty(this, "lstElementAll", _descriptor7, this);

      _initializerDefineProperty(this, "draggable", _descriptor8, this);

      _defineProperty(this, "propDrag", new DragProperties());
    }

    setPosition(position) {
      let correction = this.szSplitter * 0.5;

      for (let e of this.lstElement1) {
        e.style.width = `calc(${position}% - ${correction}px)`;
        e.style.visibility = position <= 0 ? 'hidden' : '';
      }

      for (let e of this.lstElement2) {
        e.style.width = `calc(${100 - position}% - ${correction}px)`;
        e.style.visibility = position >= 100 ? 'hidden' : '';
      }
    }

    initElements(element) {
      this.elSplitter = element;
      this.lstElement1 = element.parentNode.querySelectorAll(this.args.selector1), this.lstElement2 = element.parentNode.querySelectorAll(this.args.selector2);
      this.szSplitter = element.getBoundingClientRect().width;
      if ((0, _utils.isNone)(this.lstElement1) || this.lstElement1.length === 0) this.draggable = false;else if ((0, _utils.isNone)(this.lstElement2) || this.lstElement2.length === 0) this.draggable = false;else this.draggable = true;

      if (this.draggable) {
        this.lstElementAll.pushObject(element);

        for (let e of this.lstElement1) this.lstElementAll.pushObject(e);

        for (let e of this.lstElement2) this.lstElementAll.pushObject(e);

        this.setPosition(50);
      }
    }

    handleMouseMove(event) {
      if (event.buttons !== 1) {
        this.stopDragging();
        return;
      }

      this.setPosition(this.propDrag.getDragPosition(event.clientX));
    }

    mouseUp() {
      this.stopDragging();
    }

    mouseDown(event) {
      if (this.draggable) {
        event.preventDefault();
        let szElement1 = this.lstElement1[0].getBoundingClientRect().width,
            szElement2 = this.lstElement2[0].getBoundingClientRect().width,
            szComplete = szElement1 + szElement2 + this.szSplitter,
            // we do a bit pre calculation so we don't need to do it in the mouseMove handler
        offset = szElement1 - event.clientX + event.offsetX;
        this.propDrag.setData(offset, szComplete);

        for (let e of this.lstElementAll) {
          e.addEventListener('mousemove', this.handleMouseMove);
        }

        document.body.style.cursor = 'col-resize';
      }
    }

    stopDragging() {
      for (let e of this.lstElementAll) {
        e.removeEventListener('mousemove', this.handleMouseMove);
      }

      this.propDrag.clearData();
      document.body.style.cursor = 'unset';
    }

    doubleClick() {
      this.setPosition(50);
    }

    willDestroy() {
      for (let e of this.lstElement1) {
        e.style.removeProperty('width');
        e.style.removeProperty('visibility');
      }

      for (let e of this.lstElement2) {
        e.style.removeProperty('width');
        e.style.removeProperty('visibility');
      }
    }

  }, (_descriptor3 = _applyDecoratedDescriptor(_class3.prototype, "szSplitter", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "lstElement1", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class3.prototype, "lstElement2", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class3.prototype, "elSplitter", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class3.prototype, "lstElementAll", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class3.prototype, "draggable", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class3.prototype, "initElements", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "initElements"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "handleMouseMove", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "handleMouseMove"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "mouseUp", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "mouseUp"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "mouseDown", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "mouseDown"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "doubleClick", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "doubleClick"), _class3.prototype)), _class3);
  _exports.default = CbSplitterComponent;
});