define("client-base/components/cb-message-handler/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <CbMessage ...attributes @message={{this.cbMessageService.message}} @type={{this.cbMessageService.type}} @onClose={{this.cbMessageService.clear}}/>
  */
  {
    "id": "kM1V21VY",
    "block": "[[[8,[39,0],[[17,1]],[[\"@message\",\"@type\",\"@onClose\"],[[30,0,[\"cbMessageService\",\"message\"]],[30,0,[\"cbMessageService\",\"type\"]],[30,0,[\"cbMessageService\",\"clear\"]]]],null]],[\"&attrs\"],false,[\"cb-message\"]]",
    "moduleName": "client-base/components/cb-message-handler/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});