define("client-base/helpers/fmt-date", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.fmtDate = fmtDate;

  /**
   * formats a date to a nice looking german string
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString
   * @param {string|number|Date} params 
   * @param {object} dateOptions options for formatting the date object
   * @returns a formatted date string
   */
  function fmtDate(params) {
    let dateOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let dateObject = null;

    if (typeof params === 'string') {
      let dateNumber = Date.parse(params);
      if (Number.isNaN(dateNumber)) throw new Error(`Cannot parse the string ${params} to a date.`);
      dateObject = new Date(dateNumber);
    } else if (typeof params === 'number') {
      dateObject = new Date(params);
    } else if (params instanceof Date) {
      dateObject = params;
    }

    if (!dateObject) throw new Error(`Cannot create a date object for the parameter ${params}.`);
    return dateObject.toLocaleDateString('de-DE', dateOptions);
  }

  var _default = (0, _helper.helper)(fmtDate);

  _exports.default = _default;
});