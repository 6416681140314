define("client-base/components/cb-add-item/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <BsForm ...attributes class="d-flex d-print-none" @formLayout="inline" @model={{this.item}} {{on "submit" this.save}} as |form|>
  	{{yield}}
  	<form.element 
  			class="d-flex flex-fill align-items-center {{if this.errorHandler.errorMessage "has-error"}}"
  			@controlType="text"
  			@property={{this.property}}
  	as |el|>
  	 	{{#if @label}}
  			<label class="me-1 text-nowrap" for={{el.id}}>{{@label}}:</label>
  		{{/if}}
  		<div class="input-group">
  			{{yield to="before-input"}}
  			<el.control autocomplete="off" placeholder={{@placeholder}} disabled={{this.disabled}}/>
  			{{yield to="after-input"}}
  			<form.submitButton disabled={{this.disabled}}>
  				<CbLoadingIcon @show={{this.isWorking}}/> {{t "cb-add-item.button-create"}}
  			</form.submitButton>
  			{{yield to="after-button"}}
  		</div>
  	</form.element>
  </BsForm>
  {{!--
  		display error message only if we use our own error handler
  --}}
  <CbMessage @message={{this._errorHandler.errorMessage}} @small={{true}} @type="error"/>
  
  */
  {
    "id": "+w9XTRmR",
    "block": "[[[8,[39,0],[[17,1],[24,0,\"d-flex d-print-none\"],[4,[38,1],[\"submit\",[30,0,[\"save\"]]],null]],[[\"@formLayout\",\"@model\"],[\"inline\",[30,0,[\"item\"]]]],[[\"default\"],[[[[1,\"\\n\\t\"],[18,6,null],[1,\"\\n\\t\"],[8,[30,2,[\"element\"]],[[16,0,[29,[\"d-flex flex-fill align-items-center \",[52,[30,0,[\"errorHandler\",\"errorMessage\"]],\"has-error\"]]]]],[[\"@controlType\",\"@property\"],[\"text\",[30,0,[\"property\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,4],[[[1,\"\\t\\t\\t\"],[10,\"label\"],[14,0,\"me-1 text-nowrap\"],[15,\"for\",[30,3,[\"id\"]]],[12],[1,[30,4]],[1,\":\"],[13],[1,\"\\n\"]],[]],null],[1,\"\\t\\t\"],[10,0],[14,0,\"input-group\"],[12],[1,\"\\n\\t\\t\\t\"],[18,7,null],[1,\"\\n\\t\\t\\t\"],[8,[30,3,[\"control\"]],[[24,\"autocomplete\",\"off\"],[16,\"placeholder\",[30,5]],[16,\"disabled\",[30,0,[\"disabled\"]]]],null,null],[1,\"\\n\\t\\t\\t\"],[18,8,null],[1,\"\\n\\t\\t\\t\"],[8,[30,2,[\"submitButton\"]],[[16,\"disabled\",[30,0,[\"disabled\"]]]],null,[[\"default\"],[[[[1,\"\\n\\t\\t\\t\\t\"],[8,[39,4],null,[[\"@show\"],[[30,0,[\"isWorking\"]]]],null],[1,\" \"],[1,[28,[35,5],[\"cb-add-item.button-create\"],null]],[1,\"\\n\\t\\t\\t\"]],[]]]]],[1,\"\\n\\t\\t\\t\"],[18,9,null],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\t\"]],[3]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"],[8,[39,6],null,[[\"@message\",\"@small\",\"@type\"],[[30,0,[\"_errorHandler\",\"errorMessage\"]],true,\"error\"]],null],[1,\"\\n\"]],[\"&attrs\",\"form\",\"el\",\"@label\",\"@placeholder\",\"&default\",\"&before-input\",\"&after-input\",\"&after-button\"],false,[\"bs-form\",\"on\",\"yield\",\"if\",\"cb-loading-icon\",\"t\",\"cb-message\"]]",
    "moduleName": "client-base/components/cb-add-item/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});