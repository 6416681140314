define("client-base/components/cb-table/component", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/template", "@ember/utils", "@ember/debug", "client-base/utils/compare-to"], function (_exports, _component, _tracking, _object, _template, _utils, _debug, _compareTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Row = _exports.Header = _exports.Cell = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _class3, _descriptor4, _descriptor5, _descriptor6, _class5, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * @typedef {Object} CellArguments
   * 
   * @property {object} [value=''] you can show either a value or a component
   * @property {string} [component=null] string that represents the cell component
   * @property {number} [width=null] width of the cell, keep in mind, that all cells in a column should have the same width
   * @property {boolean} [editable=false] is the content of the cell editable?
   * @property {string} [customDefinition='']
   * @property {boolean} [showValue=true] should the value be shown if there is no component?
   * @property {boolean} [draggableRow=false] Is the row with this cell draggable? Only works, if the cell is the first one in the row. TODO Implement something like a RowInfo?
   * @property {boolean} [cutText=false] cut the text, if it is too long for the width of the cell?
   * @property {boolean} [wrap=true] break the text in multiple line if it is too long?
   */

  /**
   * @typedef {CellArguments} HeaderArguments
   * @property {boolean} [sortInit=false]
   * @property {boolean} [sortIndex=false]
   * @property {string} sortValue name of property for sorting objects, if you like to sort primitives, use any non-empty string, like 'plain' or 'primitive' 
   */

  /**
   * @class Cell
   * 
   * @param {CellArguments} CellArguments
   */
  class Cell extends _object.default {
    constructor(_ref) {
      let {
        value = '',
        component = null,
        width = null,
        editable = false,
        customDefinition = '',
        classNames = null,

        /** should the value be shown if there is no component? */
        showValue = true,

        /**
         * Is the row with this cell draggable?
         * Only works; if the cell is first one in the row.
         * TODO Implement something like a RowInfo?
         */
        draggableRow = false,
        cutText = false,
        wrap = true
      } = _ref;
      super();
      this.value = value;
      this.component = component;
      this.width = width;
      this.editable = editable;
      this.customDefinition = customDefinition;
      this.classNames = classNames;
      this.showValue = showValue;
      this.draggableRow = draggableRow;
      this.cutText = cutText;
      this.wrap = wrap;
    }

  }
  /**
   * @class Header
   * 
   * @param {HeaderArguments} HeaderArguments
   */


  _exports.Cell = Cell;
  let Header = (_class = class Header extends Cell {
    constructor(_ref2) {
      let {
        value,
        component,
        width,
        editable,
        customDefinition,
        classNames,
        showValue,
        draggableRow,
        cutText,
        wrap,
        // arguments specific for a header
        sortInit = false,
        sortValue = null
      } = _ref2;
      super({
        value,
        component,
        width,
        editable,
        customDefinition,
        classNames,
        showValue,
        draggableRow,
        cutText,
        wrap
      });

      _initializerDefineProperty(this, "sortIndex", _descriptor, this);

      _initializerDefineProperty(this, "sorted", _descriptor2, this);

      _initializerDefineProperty(this, "reverse", _descriptor3, this);

      this.sortInit = sortInit;
      this.sortValue = sortValue;
    }

    get sortable() {
      return !(0, _utils.isEmpty)(this.sortValue);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sortIndex", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sorted", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "reverse", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class);
  _exports.Header = Header;
  let Row = (_class3 = class Row extends Array {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "dropTarget", _descriptor4, this);

      _initializerDefineProperty(this, "index", _descriptor5, this);

      _initializerDefineProperty(this, "selected", _descriptor6, this);
    }

  }, (_descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "dropTarget", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class3.prototype, "index", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return -1;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class3.prototype, "selected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class3);
  /**
   * @class CbTableComponent
   * @param {CbTable.Cell[][]} data
   * @param {number} [cntFixedColumns=0]
   * @param {number} [cntFixedRows=0]
   * @param {Function} customComparator
   * @param {Function} actionDropRow
   * @param {Function} actionDragRow
   * @param {Function} actionSelectRow
   * @param {Function} actionChangedData
   * @param {number} selectedRow
   * @param {boolean} [visible=true]
   * @param {string} bodyHeight
   */

  _exports.Row = Row;
  let CbTableComponent = (_class5 = class CbTableComponent extends _component.default {
    get visible() {
      return this.args.visible ?? true;
    }

    get cntFixedColumns() {
      return this.args.cntFixedColumns ?? 0;
    }

    get cntFixedRows() {
      return this.args.cntFixedRows ?? 0;
    }

    get haveFixedColumns() {
      return this.cntFixedColumns > 0;
    }

    get haveFixedRows() {
      return this.cntFixedRows > 0;
    }

    get styleBody() {
      let style = '';

      if (this.args.bodyHeight) {
        style = `max-height: ${this.args.bodyHeight}`;
      }

      return (0, _template.htmlSafe)(style);
    }

    get cntRows() {
      return this.rows.length;
    }

    get cntColumns() {
      let columnHeader = this.columnHeader,
          cntColumns = 0;
      if (!(0, _utils.isEmpty)(columnHeader)) cntColumns = columnHeader.objectAt(0).length;
      return cntColumns;
    }

    /**
     * TODO how can we call this method, when scrollHeight or clientHeight change?
     */
    get haveVerticalScrollbar() {
      if (this.visible && this.elementBody) {
        return this.elementBody.scrollHeight > this.elementBody.clientHeight;
      }

      return false;
    }

    get columnHeader() {
      if (!(0, _utils.isEmpty)(this.args.data) && this.cntFixedRows > 0) {
        return this.args.data.slice(0, this.cntFixedRows);
      }

      return [];
    }

    get rows() {
      if (!(0, _utils.isEmpty)(this.args.data)) {
        return this.args.data.slice(this.cntFixedRows);
      }

      return [];
    }

    get comparator() {
      return this.args.customComparator ?? this.defaultComparator;
    }

    defaultComparator(rowA, rowB, index, selector, reverse) {
      let cmp = 0,
          objA = rowA.objectAt(index),
          objB = rowB.objectAt(index); // if you need to debug the comparator, use this statement
      // console.log('===> CbTable.defaultComparator', {
      // 		objA, objB,
      // 		index, selector, reverse,
      // 		valueA: objA.value?.get?.(selector) ?? objA.value?.[selector] ?? objA.value,
      // 		valueB: objB.value?.get?.(selector) ?? objB.value?.[selector] ?? objB.value,
      // 		cmp: compareTo(
      // 			objA.value?.get?.(selector) ?? objA.value?.[selector] ?? objA.value,
      // 			objB.value?.get?.(selector) ?? objB.value?.[selector] ?? objB.value
      // 		)
      // });

      if ((0, _utils.isNone)(objA) || (0, _utils.isNone)(objB)) {
        console.error('CbTable.defaultComparator: one of the rows does not have a value at column: ', index, '\nYou either have to provide a custom sort comparator or a value for all columns in all rows.');
      } else {
        // compare ember-objects or traditional objects without a get method
        cmp = (0, _compareTo.default)(objA.value?.get?.(selector) ?? objA.value?.[selector] ?? objA.value, objB.value?.get?.(selector) ?? objB.value?.[selector] ?? objB.value);
      }

      if (reverse) cmp *= -1;
      return cmp;
    }

    getSortedRows(aSortedRows) {
      if (!(0, _utils.isEmpty)(aSortedRows)) {
        let {
          sortIndex,
          sortReverse,
          sortValueSelector
        } = this; // wenn wir einen ungültigen sortIndex haben, liefern wir einfach die alte Liste

        if (sortIndex < 0 || sortIndex >= aSortedRows[0].length || (0, _utils.isEmpty)(sortValueSelector)) return aSortedRows; // wir nehmen immer die alte, eventuell bereits sortierte Liste als Basis, so bekommen wir eine stabile Sortierung
        // jetzt wird die Liste inplace sortiert

        let localComparator = this.comparator;
        aSortedRows.sort((rowA, rowB) => localComparator(rowA, rowB, sortIndex, sortValueSelector, sortReverse));
      }

      return aSortedRows;
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "elementBody", _descriptor7, this);

      _initializerDefineProperty(this, "draggedRow", _descriptor8, this);

      _initializerDefineProperty(this, "sortIndex", _descriptor9, this);

      _initializerDefineProperty(this, "sortValueSelector", _descriptor10, this);

      _initializerDefineProperty(this, "sortReverse", _descriptor11, this);

      _initializerDefineProperty(this, "sortedRows", _descriptor12, this);

      let aSortIndex = this.initSortIndex();
      this.sortedRows = this.getSortedRows(this.rows);
    }

    initSortIndex() {
      let columnHeader = this.columnHeader,
          aSortIndex = -1,
          foundSortedColumn = false;

      if (!(0, _utils.isEmpty)(this.columnHeader)) {
        // we need to initialize the properties for sorting for each column header
        for (let indexRow = 0; indexRow < columnHeader.length; indexRow++) {
          let row = columnHeader[indexRow];
          console.assert(!(0, _utils.isNone)(row), 'CbTable.didInsert2 Undefined row:', indexRow, 'data:', this.args.data);
          (false && !(!(0, _utils.isNone)(row)) && (0, _debug.assert)('CbTable.didInserr2 Undefined row: ' + indexRow, !(0, _utils.isNone)(row)));

          for (let indexColumn = 0; indexColumn < row.length; indexColumn++) {
            let cell = row[indexColumn];
            console.assert(!(0, _utils.isNone)(cell), 'CbTable.didInsert2 Undefined cell row:', indexRow, 'column:', indexColumn, 'data:', this.args.data);
            (false && !(!(0, _utils.isNone)(cell)) && (0, _debug.assert)('CbTable.didInsert2 Undefined cell row: ' + indexRow + ', column:' + indexColumn, !(0, _utils.isNone)(cell)));

            if (cell.sortable) {
              cell.sortIndex = indexColumn;

              if (!foundSortedColumn) {
                // one column can be initially sorted
                if (cell.sorted || cell.sortInit) {
                  aSortIndex = indexColumn;
                }

                if (cell.sorted) foundSortedColumn = true;
              }
            }
          }
        }
      }

      return aSortIndex;
    }

    didInsertBody(element) {
      this.elementBody = element;
    }

    didUpdateData() {
      let aSortIndex = this.initSortIndex();

      if (this.sortIndex === -1) {
        this.sort(aSortIndex);
      } else {
        this.sortedRows = this.getSortedRows(this.rows);
      }
    }

    sort(aSortIndex) {
      let sortReverse = false;

      for (let row of this.columnHeader) {
        for (let cell of row) {
          if (aSortIndex === -1 || aSortIndex !== cell.sortIndex) {
            // this is not the column we want to sort
            cell.sorted = false;
            cell.reverse = false;
          } else {
            if (cell.sorted) {
              // if the column is already sorted, reverse it
              sortReverse = cell.toggleProperty('reverse');
            } else {
              // otherwise set reverse to false
              cell.sorted = true;
              sortReverse = cell.reverse = false;
            }
          }
        }
      }

      if (aSortIndex === -1) {
        this.sortValueSelector = null;
      } else {
        for (let row of this.columnHeader) {
          let sortValueSelector = row[aSortIndex]?.sortValue;

          if (!(0, _utils.isEmpty)(sortValueSelector)) {
            this.sortValueSelector = sortValueSelector;
            break;
          }
        }
      }

      this.sortIndex = aSortIndex;
      this.sortReverse = sortReverse; // this.sortedRows = this.getSortedRows(this.sortedRows ?? this.rows);

      this.sortedRows = this.getSortedRows(this.rows);
    }

    scroll(event) {
      let tbody = event.srcElement,
          scrollLeft = tbody.scrollLeft;

      for (let thead of tbody.parentNode.querySelectorAll('.thead')) {
        thead.scrollLeft = scrollLeft;
      }
    }

  }, (_descriptor7 = _applyDecoratedDescriptor(_class5.prototype, "elementBody", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class5.prototype, "draggedRow", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class5.prototype, "sortIndex", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return -1;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class5.prototype, "sortValueSelector", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class5.prototype, "sortReverse", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class5.prototype, "sortedRows", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class5.prototype, "didInsertBody", [_object.action], Object.getOwnPropertyDescriptor(_class5.prototype, "didInsertBody"), _class5.prototype), _applyDecoratedDescriptor(_class5.prototype, "didUpdateData", [_object.action], Object.getOwnPropertyDescriptor(_class5.prototype, "didUpdateData"), _class5.prototype), _applyDecoratedDescriptor(_class5.prototype, "sort", [_object.action], Object.getOwnPropertyDescriptor(_class5.prototype, "sort"), _class5.prototype), _applyDecoratedDescriptor(_class5.prototype, "scroll", [_object.action], Object.getOwnPropertyDescriptor(_class5.prototype, "scroll"), _class5.prototype)), _class5);
  _exports.default = CbTableComponent;
});