define("client-base/components/cb-table/cb-cell/component", ["exports", "@glimmer/component", "@ember/object", "@ember/utils", "@ember/template"], function (_exports, _component, _object, _utils, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  /**
   * @class CbTableCellComponent
   * @param cell
   * @param actionSelect
   * @param actionSorted
   * @param actionChangedData
   * @param debug
   * @param errorHandler
   */
  let CbTableCellComponent = (_class = class CbTableCellComponent extends _component.default {
    get iconSort() {
      if (this.args.cell.sorted) {
        if (this.args.cell.reverse) return 'chevron-up';else return 'chevron-down';
      }

      return '';
    }

    get clickable() {
      return this.args.cell.sortable || this.args.actionSelect;
    }

    get style() {
      let width = this.args.cell.width,
          style = '';

      if (!(0, _utils.isEmpty)(width)) {
        if (width[width.length - 1] === '%') {
          width = width.replace('%', '');
          style = `flex-grow: ${width}; flex-shrink: 1; flex-basis: 0;`;
        } else {
          style = `width: ${width};`;
        }
      }

      return (0, _template.htmlSafe)(style);
    }

    click() {
      if (!(0, _utils.isNone)(this.args.actionSelect)) {
        this.args.actionSelect(this.args.cell.item);
      } else if (this.args.cell.sortable) {
        this.args.actionSorted?.(this.args.cell.sortIndex);
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "click", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "click"), _class.prototype)), _class);
  _exports.default = CbTableCellComponent;
});