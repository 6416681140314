define("client-base/components/cb-table/cb-row/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.show}}
  	{{! 
  			for the empty rows we need to use another type, because we do not want to select it with :nth-of-type 
  			in this case we do not implement error handling, because an empty row should not produce an error,
  			change this if required
  	}}
  	{{#if this.firstRow.value.empty}}
  		<span 
  				class="trow empty {{if @row.dropTarget "dropTarget"}}"
  				ondragover = {{this.dragOver}}
  				ondrop = {{this.drop}}>
  			{{#each this.cells as |cell|}}
  				<CbTable::CbCell @cell={{cell}} @actionSelect={{@actionSelect}}/>
  			{{/each}}
  		</span>
  	{{else}}
  		<div 
  				role="button"
  				class = {{concat "trow"
  						(if @actionSelectRow " clickable")
  						(if @row.selected " selected")
  						(if @row.dropTarget " dropTarget")
  						(if this.errorHandler.isError " error")
  				}}
  				draggable = {{this.draggable}}
  				onclick = {{this.selectRow}}
  				ondragstart = {{this.dragStart}}
  				ondragover = {{this.dragOver}}
  				ondragend = {{this.dragEnd}}
  				ondrop = {{this.drop}}
  		>
  			{{#each this.cells as |cell|}}
  				<CbTable::CbCell
  						@cell = {{cell}}
  						@actionSelect = {{@actionSelect}} 
  						@actionChangedData = {{@actionChangedData}}
  						@errorHandler = {{this.errorHandler}}/>
  			{{/each}}
  			<CbMessage
  					@message={{this.errorHandler.errorMessage}}
  					@small={{true}}
  					@additionalClasses={{if this.firstRow.value.isGroupItem "group-item"}}
  			/>
  		</div>
  	{{/if}}
  {{/if}}
  
  
  */
  {
    "id": "8yJPED+K",
    "block": "[[[41,[30,0,[\"show\"]],[[[41,[30,0,[\"firstRow\",\"value\",\"empty\"]],[[[1,\"\\t\\t\"],[10,1],[15,0,[29,[\"trow empty \",[52,[30,1,[\"dropTarget\"]],\"dropTarget\"]]]],[15,\"ondragover\",[30,0,[\"dragOver\"]]],[15,\"ondrop\",[30,0,[\"drop\"]]],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"cells\"]]],null]],null],null,[[[1,\"\\t\\t\\t\\t\"],[8,[39,3],null,[[\"@cell\",\"@actionSelect\"],[[30,2],[30,3]]],null],[1,\"\\n\"]],[2]],null],[1,\"\\t\\t\"],[13],[1,\"\\n\"]],[]],[[[1,\"\\t\\t\"],[10,0],[14,\"role\",\"button\"],[15,0,[28,[37,4],[\"trow\",[52,[30,4],\" clickable\"],[52,[30,1,[\"selected\"]],\" selected\"],[52,[30,1,[\"dropTarget\"]],\" dropTarget\"],[52,[30,0,[\"errorHandler\",\"isError\"]],\" error\"]],null]],[15,\"draggable\",[30,0,[\"draggable\"]]],[15,\"onclick\",[30,0,[\"selectRow\"]]],[15,\"ondragstart\",[30,0,[\"dragStart\"]]],[15,\"ondragover\",[30,0,[\"dragOver\"]]],[15,\"ondragend\",[30,0,[\"dragEnd\"]]],[15,\"ondrop\",[30,0,[\"drop\"]]],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"cells\"]]],null]],null],null,[[[1,\"\\t\\t\\t\\t\"],[8,[39,3],null,[[\"@cell\",\"@actionSelect\",\"@actionChangedData\",\"@errorHandler\"],[[30,5],[30,3],[30,6],[30,0,[\"errorHandler\"]]]],null],[1,\"\\n\"]],[5]],null],[1,\"\\t\\t\\t\"],[8,[39,5],null,[[\"@message\",\"@small\",\"@additionalClasses\"],[[30,0,[\"errorHandler\",\"errorMessage\"]],true,[52,[30,0,[\"firstRow\",\"value\",\"isGroupItem\"]],\"group-item\"]]],null],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\"]],[]]]],[]],null],[1,\"\\n\"]],[\"@row\",\"cell\",\"@actionSelect\",\"@actionSelectRow\",\"cell\",\"@actionChangedData\"],false,[\"if\",\"each\",\"-track-array\",\"cb-table/cb-cell\",\"concat\",\"cb-message\"]]",
    "moduleName": "client-base/components/cb-table/cb-row/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});