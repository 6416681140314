define("client-base/components/cb-table/cb-cell/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!--
  	We add the event handler only if we are clickable. And then we set the right role.
  	But template-lint is too dumb to recognize this.
  --}}
  {{!-- template-lint-disable no-invalid-interactive --}}
  <span href="#"
  		role = {{if this.clickable "button" "gridcell"}}
  		style = {{this.style}}
  		class = {{concat
  				@cell.classNames 
  				(if this.clickable " clickable")
  				(if @debug " debug")
  				(if @cell.width " flex-fix")
  				(if @cell.cutText " text-kuerzen")
  				(unless @cell.wrap " text-nowrap")
  		}}
  		onclick = {{if this.clickable this.click}}
  >
  	{{#if @cell.component}}
  		{{component
  				@cell.component
  				value = @cell.value
  				editable = @cell.editable
  				errorHandler = @errorHandler
  				customDefinition = @cell.customDefinition
  				actionChangedData = @actionChangedData
  		}}
  	{{else if @cell.showValue}}
  		{{@cell.value}}
  	{{/if}}
  	{{#if @cell.sorted}}
  		<FaIcon @icon={{this.iconSort}} @size="xs" class="sort-marker"/>
  	{{/if}}
  </span>
  
  */
  {
    "id": "A02lyUkU",
    "block": "[[[10,1],[14,6,\"#\"],[15,\"role\",[52,[30,0,[\"clickable\"]],\"button\",\"gridcell\"]],[15,5,[30,0,[\"style\"]]],[15,0,[28,[37,1],[[30,1,[\"classNames\"]],[52,[30,0,[\"clickable\"]],\" clickable\"],[52,[30,2],\" debug\"],[52,[30,1,[\"width\"]],\" flex-fix\"],[52,[30,1,[\"cutText\"]],\" text-kuerzen\"],[52,[51,[30,1,[\"wrap\"]]],\" text-nowrap\"]],null]],[15,\"onclick\",[52,[30,0,[\"clickable\"]],[30,0,[\"click\"]]]],[12],[1,\"\\n\"],[41,[30,1,[\"component\"]],[[[1,\"\\t\\t\"],[46,[30,1,[\"component\"]],null,[[\"value\",\"editable\",\"errorHandler\",\"customDefinition\",\"actionChangedData\"],[[30,1,[\"value\"]],[30,1,[\"editable\"]],[30,3],[30,1,[\"customDefinition\"]],[30,4]]],null],[1,\"\\n\"]],[]],[[[41,[30,1,[\"showValue\"]],[[[1,\"\\t\\t\"],[1,[30,1,[\"value\"]]],[1,\"\\n\\t\"]],[]],null]],[]]],[41,[30,1,[\"sorted\"]],[[[1,\"\\t\\t\"],[8,[39,4],[[24,0,\"sort-marker\"]],[[\"@icon\",\"@size\"],[[30,0,[\"iconSort\"]],\"xs\"]],null],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@cell\",\"@debug\",\"@errorHandler\",\"@actionChangedData\"],false,[\"if\",\"concat\",\"unless\",\"component\",\"fa-icon\"]]",
    "moduleName": "client-base/components/cb-table/cb-cell/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});