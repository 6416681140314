define("client-base/components/cb-table/cb-editable-cell/component", ["exports", "@glimmer/component", "client-base/utils/error-handler"], function (_exports, _component, _errorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * @param {boolean} [editable=false]
   * @param {ErrorHandler} [errorHandler=new ErrorHandler()]
   */
  class CbTableEditableCellComponent extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "_errorHandler", null);
    }

    get editable() {
      return this.args.editable ?? false;
    }

    createErrorHandler() {
      if (this._errorHandler === null) {
        this._errorHandler = new _errorHandler.default();
      }

      return this._errorHandler;
    }

    get errorHandler() {
      return this.args.errorHandler ?? this.createErrorHandler();
    }
    /**
     * saves the value of the cell
     * the request is only send, if the cell is editable and hasDirtyAttributes is true
     * @returns a promise, if there is nothing to save, returns an always resolving promise
     */


    save() {
      if (this.editable && this.args.value.get('hasDirtyAttributes')) {
        let promise = this.args.value.save();
        promise.then(() => this.isDestroyed ? null : this.errorHandler.clear()).catch(error => {
          this.errorHandler.handleRestError('cb-table.editable-cell.save', error, 'Konnte nicht gespeichert werden.');
          this.args.value.rollbackAttributes();
        });
        return promise;
      } // the always resolving promise


      return new Promise(resolve => resolve());
    }

  }

  _exports.default = CbTableEditableCellComponent;
});