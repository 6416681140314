define("client-base/components/cb-click-icon/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <a ...attributes
  		href="#"
  		{{on "click" @onClick}}
  		class={{concat
  				"align-self-" (if @align @align "center")
  				(if (eq @pull "right") " ms-auto")
  		}}
  >
  	<FaIcon
  			@size={{@size}}
  			@icon={{@icon}}
  			@flip={{@flip}}
  			{{!-- we leave the class symbol for historical reasons here, in the code of ebo-client it's use everywhere --}}
  			class={{concat "cb-click-icon symbol "
  					(if @selected "selected ")
  					(if @selected2 "selected2 ")
  					@classIcon
  			}}
  			@pull={{@pull}}/>
  	{{yield}}
  </a>
  */
  {
    "id": "ZtmGY6/s",
    "block": "[[[11,3],[17,1],[24,6,\"#\"],[16,0,[28,[37,0],[\"align-self-\",[52,[30,2],[30,2],\"center\"],[52,[28,[37,2],[[30,3],\"right\"],null],\" ms-auto\"]],null]],[4,[38,3],[\"click\",[30,4]],null],[12],[1,\"\\n\\t\"],[8,[39,4],[[16,0,[28,[37,0],[\"cb-click-icon symbol \",[52,[30,5],\"selected \"],[52,[30,6],\"selected2 \"],[30,7]],null]]],[[\"@size\",\"@icon\",\"@flip\",\"@pull\"],[[30,8],[30,9],[30,10],[30,3]]],null],[1,\"\\n\\t\"],[18,11,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@align\",\"@pull\",\"@onClick\",\"@selected\",\"@selected2\",\"@classIcon\",\"@size\",\"@icon\",\"@flip\",\"&default\"],false,[\"concat\",\"if\",\"eq\",\"on\",\"fa-icon\",\"yield\"]]",
    "moduleName": "client-base/components/cb-click-icon/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});