define("client-base/cb-message-service/service", ["exports", "@ember/service", "@glimmer/tracking", "@ember/object", "client-base/utils/error-handler"], function (_exports, _service, _tracking, _object, _errorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * @class CbMessageService
   */
  let CbMessageService = (_class = class CbMessageService extends _service.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "errorHandler", new _errorHandler.default());

      _initializerDefineProperty(this, "type", _descriptor, this);

      _initializerDefineProperty(this, "_message", _descriptor2, this);
    }

    setMessage(message, type) {
      this._message = message;
      this.type = type ?? 'info';
    }

    handleRestError(method, error, errorMessage) {
      this._message = null;
      this.type = 'danger';

      for (var _len = arguments.length, additionalObjects = new Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
        additionalObjects[_key - 3] = arguments[_key];
      }

      this.errorHandler.handleRestError(method, error, errorMessage, ...additionalObjects);
    }

    get message() {
      return this._message ?? this.errorHandler.errorMessage;
    }

    clear() {
      this.errorHandler.clear();
      this._message = null;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "type", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'danger';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_message", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "clear", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clear"), _class.prototype)), _class);
  _exports.default = CbMessageService;
});