define("client-base/components/cb-loading-icon/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @show}}
  	<FaIcon @icon="sync-alt" @spin={{true}} @size={{@size}} class={{if @center "mx-auto"}}/>
  {{/if}}
  */
  {
    "id": "h06AsBR6",
    "block": "[[[41,[30,1],[[[1,\"\\t\"],[8,[39,1],[[16,0,[52,[30,2],\"mx-auto\"]]],[[\"@icon\",\"@spin\",\"@size\"],[\"sync-alt\",true,[30,3]]],null],[1,\"\\n\"]],[]],null]],[\"@show\",\"@center\",\"@size\"],false,[\"if\",\"fa-icon\"]]",
    "moduleName": "client-base/components/cb-loading-icon/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});