define("client-base/components/cb-progress-bar/component", ["exports", "@glimmer/component", "@ember/template", "@ember/utils"], function (_exports, _component, _template, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class CbProgressBarComponent
   * @param {Number} firstValue
   * @param {Number} secondValue
   * @param {String} text
   * @param {Boolean} [small=false]
   */
  class CbProgressBarComponent extends _component.default {
    get small() {
      return this.args.small ?? false;
    }

    get firstValue() {
      if ((0, _utils.isPresent)(this.args.firstValue)) return Math.min(this.args.firstValue, 1);
      return null;
    }

    get styleFirstBar() {
      return (0, _template.htmlSafe)(`width: ${this.firstValue * 100}%`);
    }

    get showFirstBar() {
      return this.firstValue !== null && this.firstValue >= 0;
    }

    get secondValue() {
      if ((0, _utils.isPresent)(this.args.secondValue)) return Math.min(this.args.secondValue, 1);
      return null;
    }

    get styleSecondBar() {
      return (0, _template.htmlSafe)(`width: ${this.secondValue * 100}%`);
    }

    get showSecondBar() {
      return this.secondValue !== null && this.secondValue >= 0;
    }

    get text() {
      return (0, _template.htmlSafe)(this.args.text);
    }

    get showText() {
      return !this.small && (0, _utils.isPresent)(this.args.text);
    }

  }

  _exports.default = CbProgressBarComponent;
});