define("client-base/components/cb-table/cb-link-cell/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <CopyButton class="p-0 btn btn-link btn-lg" @text={{@cell.value}} title={{t "cb-table.cb-link-cell.copy"}}>
  	<FaIcon @icon="clone"/> {{t "cb-table.cb-link-cell.copy"}}
  </CopyButton>
  
  */
  {
    "id": "Z9kHCDdD",
    "block": "[[[8,[39,0],[[24,0,\"p-0 btn btn-link btn-lg\"],[16,\"title\",[28,[37,1],[\"cb-table.cb-link-cell.copy\"],null]]],[[\"@text\"],[[30,1,[\"value\"]]]],[[\"default\"],[[[[1,\"\\n\\t\"],[8,[39,2],null,[[\"@icon\"],[\"clone\"]],null],[1,\" \"],[1,[28,[35,1],[\"cb-table.cb-link-cell.copy\"],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@cell\"],false,[\"copy-button\",\"t\",\"fa-icon\"]]",
    "moduleName": "client-base/components/cb-table/cb-link-cell/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});