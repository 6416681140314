define("client-base/components/cb-loading-page/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @show}}
  	{{#if @title}}
  		<h2 class="mt-3 text-center cb-loading-page-title">{{@title}}</h2>
  	{{/if}}
  	<h1 class="my-3 text-center cb-loading-page-icon">
  		<CbLoadingIcon @show={{true}} @center={{true}}/>
  	</h1>
  {{/if}}
  
  */
  {
    "id": "h9w7vn3v",
    "block": "[[[41,[30,1],[[[41,[30,2],[[[1,\"\\t\\t\"],[10,\"h2\"],[14,0,\"mt-3 text-center cb-loading-page-title\"],[12],[1,[30,2]],[13],[1,\"\\n\"]],[]],null],[1,\"\\t\"],[10,\"h1\"],[14,0,\"my-3 text-center cb-loading-page-icon\"],[12],[1,\"\\n\\t\\t\"],[8,[39,1],null,[[\"@show\",\"@center\"],[true,true]],null],[1,\"\\n\\t\"],[13],[1,\"\\n\"]],[]],null]],[\"@show\",\"@title\"],false,[\"if\",\"cb-loading-icon\"]]",
    "moduleName": "client-base/components/cb-loading-page/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});