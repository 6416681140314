define("client-base/components/cb-add-item/component", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/utils", "@ember/debug", "@ember/service", "@ember/object", "client-base/utils/error-handler"], function (_exports, _component, _tracking, _utils, _debug, _service, _object, _errorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * @class CbAddItem 
   * @param {String} [property=name] the name of the property that should the value from the input field
   * @param {Model[]} [noDuplicateItems] an array of existing objects, used to check for duplicate items
   * @param {Model} [item] 
   * @param {String} [placeholder] placeholder text for the input field
   * @param {action} actionSave the action to call if the save button is pressed
   * @param {boolean} [disabled=false] disable the input field
   * @param {String} [label] the label before the input field
   * @param {ErrorHandler} [errorHandler=new ErrorHandler()] you can set an alternative error handler if you like to display the error message yourself
   * @param {String} [model] 
   * 
   * @param {String} [msgEmpty="Bitte geben Sie einen Namen an."] message for the user if the input field is empty
   * @param {String} [msgDuplicate="Diesen Namen gibt es bereits"] message for the user if there is already an item with the value in the input field
   * @param {String} [msgCreate="Das Objekt kann nicht angelegt werden"] message for the user if the item can't be created, e. g. because of a rest error
   */
  let CbAddItem = (_class = class CbAddItem extends _component.default {
    get property() {
      return this.args.property || 'name';
    }

    get disabled() {
      return this.args.disabled ?? false;
    }

    get msgEmpty() {
      return this.args.msgEmpty || 'Bitte geben Sie einen Namen an.';
    }

    get msgDuplicate() {
      return this.args.msgDuplicate || 'Diesen Namen gibt es bereits';
    }

    get msgCreate() {
      return this.args.msgCreate || 'Das Objekt kann nicht angelegt werden';
    }

    get errorHandler() {
      return this.args.errorHandler ?? this._errorHandler;
    }

    get item() {
      return this.args.item ?? this._item;
    }

    set item(newItem) {
      this._item = newItem;
    }

    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "isWorking", _descriptor2, this);

      _defineProperty(this, "_errorHandler", new _errorHandler.default());

      _initializerDefineProperty(this, "_item", _descriptor3, this);

      (false && !((0, _utils.isPresent)(this.args.item) || (0, _utils.isPresent)(this.args.model)) && (0, _debug.assert)('You have to pass an item that we can use as the new item or pass a model so we can create our own item.', (0, _utils.isPresent)(this.args.item) || (0, _utils.isPresent)(this.args.model)));
      (false && !((0, _utils.isPresent)(this.args.actionSave)) && (0, _debug.assert)('You have to pass an action to actionSave. This action is used for saving the new item.', (0, _utils.isPresent)(this.args.actionSave))); // we have to create an item, if we didn't get one as an argument

      if ((0, _utils.isNone)(this.args.item)) {
        this.createItem();
      }
    }
    /**
     * create a new item if we have a model
     */


    createItem() {
      if ((0, _utils.isPresent)(this.args.model)) {
        this.item = this.store.createRecord(this.args.model);
      }
    }

    async save() {
      let item = this.item;

      try {
        this.isWorking = true; // we like the value without superflous space

        let value = item.get(this.property)?.trim();
        item.set(this.property, value);

        if (!(0, _utils.isPresent)(value)) {
          // we can't allow "empty" items
          this.errorHandler.setErrorMessage(this.msgEmpty);
        } else if (!(0, _utils.isEmpty)(this.args.noDuplicateItems) && // we need filter ourself from the list of duplicate items
        this.args.noDuplicateItems.filter(duplicate => item !== duplicate).mapBy(this.property).includes(value)) {
          // there is already an item with this property
          this.errorHandler.setErrorMessage(this.msgDuplicate);
        } else {
          this.errorHandler.clear();
          await this.args.actionSave(this.item);
          this.createItem();
        }
      } catch (error) {
        this.errorHandler.handleRestError('CbAddItem.save', error, this.msgCreate, item); // item.rollbackAttributes();
        // item.set('property', value);
      } finally {
        this.isWorking = false;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isWorking", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_item", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class);
  _exports.default = CbAddItem;
});