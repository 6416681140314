define("client-base/components/cb-list/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#unless (is-empty @items)}}
  	<ul class="cb-list" ...attributes>
  		{{#each this.items as |item|}}
  			<CbList::ListItem @name={{@name}} @item={{item}} @selectedItem={{this.selectedItem}} @secondItem={{@secondItem}} @onSelect={{this.onSelect}} as |selected second|>
  				{{yield item selected second}}
  			</CbList::ListItem>
  		{{/each}}
  	</ul>
  {{/unless}}
  */
  {
    "id": "SMrpjwwp",
    "block": "[[[41,[51,[28,[37,1],[[30,1]],null]],[[[1,\"\\t\"],[11,\"ul\"],[24,0,\"cb-list\"],[17,2],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"items\"]]],null]],null],null,[[[1,\"\\t\\t\\t\"],[8,[39,4],null,[[\"@name\",\"@item\",\"@selectedItem\",\"@secondItem\",\"@onSelect\"],[[30,4],[30,3],[30,0,[\"selectedItem\"]],[30,5],[30,0,[\"onSelect\"]]]],[[\"default\"],[[[[1,\"\\n\\t\\t\\t\\t\"],[18,8,[[30,3],[30,6],[30,7]]],[1,\"\\n\\t\\t\\t\"]],[6,7]]]]],[1,\"\\n\"]],[3]],null],[1,\"\\t\"],[13],[1,\"\\n\"]],[]],null]],[\"@items\",\"&attrs\",\"item\",\"@name\",\"@secondItem\",\"selected\",\"second\",\"&default\"],false,[\"unless\",\"is-empty\",\"each\",\"-track-array\",\"cb-list/list-item\",\"yield\"]]",
    "moduleName": "client-base/components/cb-list/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});