define("client-base/helpers/cb-call", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   * Helper for calling a function of a controller or a component in a template
   * use it this way:
   * {{call this function_name argument_1 argument_2 ...}}
   * @param this - the context in which the function is called; mandatory
   * @param function_name - name of the function to be called; mandatory
   * @param argument_x - arguments for the function
   */
  var _default = (0, _helper.helper)(param => {
    let scope = param[0];
    let fn = param[1];
    let args = param.slice(2); // we leave the log statement in case we need it for debugging
    // console.log('--- call.call', {scope, fn, args});

    if (fn instanceof Function) {
      return fn.apply(scope, args);
    } else {
      console.error(`Error in helper call: the second parameter needs to be a function. You gave me: ${fn}. This is a ${typeof fn}.`);
      return null;
    }
  });

  _exports.default = _default;
});