define("client-base/components/cb-scroll-to-top/component", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CbScrollToTopComponent = (_class = class CbScrollToTopComponent extends _component.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "fade", _descriptor, this);

      _initializerDefineProperty(this, "elScroll", _descriptor2, this);

      window.addEventListener('scroll', this.scroll);
    }

    didInsert(element) {
      this.elScroll = element;
    }

    willDestroy() {
      super.willDestroy(...arguments);
      window.removeEventListener('scroll', this.scroll);
      this.elScroll.removeEventListener('animationend', this.animationEnd);
    }

    scroll() {
      if (window.scrollY > 600) {
        this.fade = 'fade-in';
      } else if (this.fade === 'fade-in') {
        this.fade = 'fade-out';
        this.elScroll.addEventListener('animationend', this.animationEnd);
      }
    }

    animationEnd() {
      this.fade = '';
      this.elScroll.removeEventListener('animationend', this.animationEnd);
    }

    backToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fade", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "elScroll", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "scroll", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "scroll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "animationEnd", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "animationEnd"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "backToTop", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "backToTop"), _class.prototype)), _class);
  _exports.default = CbScrollToTopComponent;
});