define("client-base/utils/error-handler", ["exports", "@ember/template", "@ember/utils", "@glimmer/tracking"], function (_exports, _template, _utils, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ErrorHandler = (_class = class ErrorHandler {
    constructor() {
      _initializerDefineProperty(this, "errorMessage", _descriptor, this);

      _initializerDefineProperty(this, "successMessage", _descriptor2, this);
    }

    /**
     * Just sets the successMessage, nothing fancy
     *  
     * @param {string} message 
     */
    setSuccessMessage(message) {
      this.successMessage = message;
    }
    /**
     * Just sets the errorMessage, nothing fancy
     *  
     * @param {string} message 
     */


    setErrorMessage(message) {
      this.errorMessage = message;
    }
    /**
     * produce a string in the form "message: secondMessage"
     * if message ends with a dot, remove it
     * @param {String} message the base message
     * @param {String} secondMessage the message to add
     */


    addErrorMessage(message, secondMessage) {
      message = message.trim();

      if (message.length > 0) {
        if (message.charAt(message.length - 1) === '.') {
          message = message.slice(0, message.length - 1);
        }
      }

      return message + ': ' + secondMessage;
    }
    /**
     * modify the errorMessage with information from the error object
     * @param {*} error should be an instance of JSON API Error class, 
     * 		but often is something else, we cover all known variants
     * @param {String} errorMessage the base message to present the user
     */


    modifyErrorMessage(error, errorMessage) {
      if (error?.status === 404) {
        errorMessage = this.addErrorMessage(errorMessage, 'Der Server ist nicht erreichbar');
      } else if (error) {
        if (error.text && !error.errors) {
          // sometimes the json api error is transmitted as a string in the text property, so we try to parse it
          // ember-simple-auth does this
          try {
            error.errors = JSON.parse(error.text)["errors"];
            error.text = undefined;
          } catch (error) {// can not parse error.text so it's propably not JSON
            // nothing to do here
          }
        }

        if ((0, _utils.typeOf)(error) === 'string') {
          // Where and when is this used?
          // It is a remainer of the old error handling in the login box, 
          // but is it still possible that error is just a plain string?
          errorMessage = (0, _template.htmlSafe)('Der Server hat einen Fehler gemeldet: <br/>' + error.replace(/[\s\S]*<title>(.*)<\/title>[\s\S]*/, '$1'));
        } else if (error.text) {
          // the error from ember-simple-auth
          if (error.status === 503) {
            errorMessage = this.addErrorMessage(errorMessage, error.text.replace(/[\s\S]*<title>(.*)<\/title>[\s\S]*/, '$1'));
          } else if (error.status === 403) {
            errorMessage = this.addErrorMessage(errorMessage, error.text);
          } else if (error.statusText) {
            errorMessage = this.addErrorMessage(errorMessage, error.statusText);
          } else {
            errorMessage = this.addErrorMessage(errorMessage, error.text);
          }
        } else if (error.errors && error.errors[0]) {
          // the normal json api error
          let objError = error.errors[0];
          errorMessage = this.addErrorMessage(errorMessage, objError.title ? objError.title : objError.detail);
        } else if (error.body && error.body.errors && error.body.errors[0]) {
          // for some status codes the json api error is wrapped in a body element
          // with the body you get a headers array
          let objError = error.body.errors[0];
          errorMessage = this.addErrorMessage(errorMessage, objError.title ? objError.title : objError.detail);
        } else if (error.message) {
          // that's more or less the default error if none of the other handlers is ... ¿handling?
          // Apache responds that Tomcat is unavailable
          // --- <calling publickey> 503 Request was rejected due to server error
          // Apache is unavailable
          // --- <calling publickey> The ajax operation was aborted
          // --- <calling authenticate> Network request failed
          errorMessage = this.addErrorMessage(errorMessage, error.message);
        } else if (error.errorThrown && error.textStatus) {
          // the method raw from the package ember-ajax produced this error when downloading the template file
          // but is this a general error or specific to ember-ajax?
          errorMessage = this.addErrorMessage(errorMessage, `${error.textStatus}: ${error.errorThrown}`);
        } else {
          console.error('rest-error-handler new type of error:', {
            error
          }); // in this case we have a new type of error, that we don't handle
          // we put a breakpoint here so the developer must handle this error
          // eslint-disable-next-line no-debugger

          debugger;
        }
      }

      return errorMessage;
    }
    /**
     * handles errors from the rest api in a well defined manner
     * add the error title or details to the errorMessage
     * @param {*} method the calling method, will be outputed to the console
     * @param {*} error should be an instance of JSON API Error class, 
     * 		but often is something else, we cover all known variants
     * @param {string} errorMessage the base message to present the user
     * @param {*} ...additionalObjects  additional objects that will be printed to the console
     */


    handleRestError(method, error, errorMessage) {
      for (var _len = arguments.length, additionalObjects = new Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
        additionalObjects[_key - 3] = arguments[_key];
      }

      this.handleRestError2(method, error, this, errorMessage, ...additionalObjects);
    }
    /**
     * handles errors from the rest api in a well defined manner
     * add the error title or details to the errorMessage
     * @param {*} method the calling method, will be outputed to the console
     * @param {*} error should be an instance of JSON API Error class, 
     * 		but often is something else, we cover all known variants
     * @param {*} errorReceiver the object where the errorMessage should be set
     * @param {string} errorMessage the base message to present the user
     * @param {*} ...additionalObjects  additional objects that will be printed to the console
     */


    handleRestError2(method, error, errorReceiver, errorMessage) {
      errorMessage = this.modifyErrorMessage(error, errorMessage);

      for (var _len2 = arguments.length, additionalObjects = new Array(_len2 > 4 ? _len2 - 4 : 0), _key2 = 4; _key2 < _len2; _key2++) {
        additionalObjects[_key2 - 4] = arguments[_key2];
      }

      this.outputError2(method, error, errorReceiver, errorMessage, ...additionalObjects);
    }
    /**
     * output the errors from the rest api in a well defined manner
     * does not modify the errorMessage
     * @param {*} method the calling method, will be outputed to the console
     * @param {*} error should be an instance of JSON API Error class, 
     * 		but often is something else, we cover all known variants
     * @param {string} errorMessage the base message to present the user
     * @param {*} ...additionalObjects  additional objects that will be printed to the console
     */


    outputError(method, error, errorMessage) {
      for (var _len3 = arguments.length, additionalObjects = new Array(_len3 > 3 ? _len3 - 3 : 0), _key3 = 3; _key3 < _len3; _key3++) {
        additionalObjects[_key3 - 3] = arguments[_key3];
      }

      this.outputError2(method, error, this, errorMessage, ...additionalObjects);
    }
    /**
     * output the errors from the rest api in a well defined manner
     * does not modify the errorMessage
     * @param {*} method the calling method, will be outputed to the console
     * @param {*} error should be an instance of JSON API Error class, 
     * 		but often is something else, we cover all known variants
     * @param {*} errorReceiver the object where the errorMessage should be set
     * @param {string} errorMessage the base message to present the user
     * @param {*} ...additionalObjects  additional objects that will be printed to the console
     */


    outputError2(method, error, errorReceiver, errorMessage) {
      errorReceiver.errorMessage = errorMessage;
      let errors = error?.errors ?? error?.body?.errors;

      for (var _len4 = arguments.length, additionalObjects = new Array(_len4 > 4 ? _len4 - 4 : 0), _key4 = 4; _key4 < _len4; _key4++) {
        additionalObjects[_key4 - 4] = arguments[_key4];
      }

      console.error('method:', method, '\nerrorMessage:', errorMessage, '\nerror javascript:', error, '\nerror server:', errors && errors[0] ? errors[0] : '', '\nadditionalObjects:', ...additionalObjects);
    }
    /**
     * Clear the errorMessage
     */


    clear() {
      this.errorMessage = null;
      this.successMessage = null;
    }
    /**
     * Is there a errorMessage?
     * @returns true if there is an errorMessage
     */


    get isError() {
      return this.errorMessage != null;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "errorMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "successMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class);
  _exports.default = ErrorHandler;
});