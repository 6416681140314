define("client-base/components/cb-scroll-to-top/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <BsButton @type="primary" @onClick={{this.backToTop}} class="scroll-to-top {{this.fade}}" {{did-insert this.didInsert}}>
  	<FaIcon @icon="angle-double-up"/>
  </BsButton>
  */
  {
    "id": "nm8h5ZRe",
    "block": "[[[8,[39,0],[[16,0,[29,[\"scroll-to-top \",[30,0,[\"fade\"]]]]],[4,[38,1],[[30,0,[\"didInsert\"]]],null]],[[\"@type\",\"@onClick\"],[\"primary\",[30,0,[\"backToTop\"]]]],[[\"default\"],[[[[1,\"\\n\\t\"],[8,[39,2],null,[[\"@icon\"],[\"angle-double-up\"]],null],[1,\"\\n\"]],[]]]]]],[],false,[\"bs-button\",\"did-insert\",\"fa-icon\"]]",
    "moduleName": "client-base/components/cb-scroll-to-top/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});