define("client-base/components/cb-screenshot/component", ["exports", "@glimmer/component", "@ember/object", "html2canvas"], function (_exports, _component, _object, _html2canvas) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  // we create an extra object so we don't pollute our component
  // eslint-disable-next-line ember/no-mixins
  // import FileSaverMixin from 'ember-cli-file-saver/mixins/file-saver';
  // class FileSaver extends EmberObject.extend(FileSaverMixin) {}

  /**
   * saves a screenshot of a given dom element
   * @class ScreenshotComponent
   * @param {String} selector the selector of the dom element to be saved
   * @param {String} filename the filename of file to save
   */
  let ScreenshotComponent = (_class = class ScreenshotComponent extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "imageType", "image/png");

      _defineProperty(this, "options", {});
    }

    async saveScreenshot() {
      // if some element needed to be modified for taking a screenshot, just add a class print-modify to these elements
      // you can style the elements with .print-modify.printing {...}
      // we need to use this hackish way, because if we use the option onclone, the stylesheet for 
      // the class .printing is not cloned  - adding the printing-class there is too late
      // and in the option ignoreElements we would also modify the main dom and not the clone
      for (let elModify of document.querySelectorAll(`${this.args.selector} .print-modify`)) {
        elModify.classList.add('printing');
      }

      let canvas = await (0, _html2canvas.default)(document.querySelector(this.args.selector), this.options); // remove the previously added class

      for (let elModify of document.querySelectorAll(`${this.args.selector} .print-modify`)) {
        elModify.classList.remove('printing');
      }

      let link = document.createElement('a');
      link.download = this.args.filename;
      link.href = canvas.toDataURL();
      link.click(); // the FileSaverMixin is another approach to download a file
      // but in this case I don't see a benefit in using an additional library - canvas gives us a ready to use URI
      // canvas.toBlob(blob => this.fileSaver.saveFileAs(this.args.filename, blob, this.imageType));
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "saveScreenshot", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveScreenshot"), _class.prototype)), _class);
  _exports.default = ScreenshotComponent;
});