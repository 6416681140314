define("client-base/utils/compare-to", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.compareTo = compareTo;
  _exports.default = void 0;
  _exports.sorterByFields = sorterByFields;

  /**
   * compares two numbers
   * @param {Number} a 
   * @param {Number} b 
   * @returns -1 if b is bigger than a; 0 if a and b are equal; if a is bigger than b
   */
  function compareNumber(a, b) {
    return a - b;
  }
  /**
   * compares two boolean values
   * @param {boolean} a 
   * @param {boolean} b 
   * @returns -1 if b is true and a false; 0 if both a and b are true or false; 1 a is true and b false
   */


  function compareBoolean(a, b) {
    return a == b ? 0 : a ? 1 : 0;
  }
  /**
   * compares two strings
   * @param {String} a 
   * @param {String} b 
   * @returns -1 if b comes before a; 0 if a and b could be at the same place; 1 if a comes before b
   */


  function compareString(a, b) {
    return a < b ? -1 : a === b ? 0 : 1;
  }
  /**
   * compares a and b and returns which should come first in a list
   * 
   * You can use this method with the sort function of an array
   * 
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort#Syntax
   * 
   * <code>ar.sort((a, b) => compareTo(a, b))</code>
   * 
   * @param a
   * @param b
   * @returns -1 if b comes before a; 0 if a and b could be at the same place; 1 if a comes before b
   */


  function compareTo(a, b) {
    let typeA = (0, _utils.typeOf)(a),
        typeB = (0, _utils.typeOf)(b);

    if (typeA === typeB) {
      switch (typeA) {
        case 'number':
          return compareNumber(a, b);

        case 'boolean':
          return compareBoolean(a, b);

        case 'string':
          return compareString(a.toUpperCase(), b.toUpperCase());
      }
    }

    if ((0, _utils.isNone)(a)) a = '';
    if ((0, _utils.isNone)(b)) b = '';
    return compareString(a.toString(), b.toString());
  }
  /**
   * Creates a custom sorting function that can be used with the `sort` function
   * of native arrays. This function is intended to replace the `sortBy` function
   * of Ember.js and supports sorting by multiple fields in ascending order.
   * The sorting fields can be attributes from Ember Data models or any comparable values.
   *
   * @param {...string} sortFields - The list of field names to sort by. The order of
   *                                fields determines the priority of sorting.
   * @returns {Function} A custom sorting function that can be used with the `sort`
   *                     function of native arrays.
   *
   * @example
   * const data = [
   *   { name: 'John', age: 30 },
   *   { name: 'Jane', age: 25 },
   *   { name: 'Alice', age: 28 }
   * ];
   *
   * const sortedData = data.sort(sorterByFields('age', 'name'));
   * // Result: [{ name: 'Jane', age: 25 }, { name: 'Alice', age: 28 }, { name: 'John', age: 30 }]
   */


  function sorterByFields() {
    for (var _len = arguments.length, sortFields = new Array(_len), _key = 0; _key < _len; _key++) {
      sortFields[_key] = arguments[_key];
    }

    return (a, b) => {
      let cmp = 0;

      for (const field of sortFields) {
        cmp = compareTo(a[field], b[field]);
        if (cmp !== 0) break;
      }

      return cmp;
    };
  }

  var _default = compareTo;
  _exports.default = _default;
});