define("client-base/components/cb-splitter/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
  		...attributes
  		role="button"
  		class="{{this.selectorSplitter}} {{if this.draggable "draggable"}}"
  		{{did-insert this.initElements}}
  		{{on "mouseup" this.mouseUp}}
  		{{!-- we need the mouse down event, how else could we implement a draggable element? --}}
  		{{! template-lint-disable no-down-event-binding}}
  		{{on "mousedown" this.mouseDown}}
  		{{on "dblclick" this.doubleClick}}
  >
  	<span/>
  </div>
  */
  {
    "id": "CzlZxXaW",
    "block": "[[[11,0],[17,1],[24,\"role\",\"button\"],[16,0,[29,[[30,0,[\"selectorSplitter\"]],\" \",[52,[30,0,[\"draggable\"]],\"draggable\"]]]],[4,[38,1],[[30,0,[\"initElements\"]]],null],[4,[38,2],[\"mouseup\",[30,0,[\"mouseUp\"]]],null],[4,[38,2],[\"mousedown\",[30,0,[\"mouseDown\"]]],null],[4,[38,2],[\"dblclick\",[30,0,[\"doubleClick\"]]],null],[12],[1,\"\\n\\t\"],[10,1],[12],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"if\",\"did-insert\",\"on\"]]",
    "moduleName": "client-base/components/cb-splitter/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});