define("client-base/components/cb-collapse/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <CbClickIcon
  		class="cb-collapse-link"
  		@icon={{if this.show "angle-down" "angle-right"}}
  		@classIcon={{if this.contained "contained" "not-contained"}}
  		@onClick={{this.toggleShow}}
  >
  	{{this.title}}<hr>
  </CbClickIcon>
  {{#if this.show}}
  	{{yield}}
  {{/if}}
  */
  {
    "id": "cp46+Kjc",
    "block": "[[[8,[39,0],[[24,0,\"cb-collapse-link\"]],[[\"@icon\",\"@classIcon\",\"@onClick\"],[[52,[30,0,[\"show\"]],\"angle-down\",\"angle-right\"],[52,[30,0,[\"contained\"]],\"contained\",\"not-contained\"],[30,0,[\"toggleShow\"]]]],[[\"default\"],[[[[1,\"\\n\\t\"],[1,[30,0,[\"title\"]]],[10,\"hr\"],[12],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[41,[30,0,[\"show\"]],[[[1,\"\\t\"],[18,1,null],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"cb-click-icon\",\"if\",\"yield\"]]",
    "moduleName": "client-base/components/cb-collapse/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});