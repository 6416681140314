define("client-base/components/cb-table/cb-editable-text-cell/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- template-lint-disable no-down-event-binding --}}
  {{!-- We like to use both up and down event --}}
  <div
  		...attributes
  		tabindex = {{if this.editable 0}}
  		{{on "mousedown" this.mouseDown}}
  		{{on "mouseup" this.mouseUp}}
  		{{on "focusin" this.focusIn}}
  >
  	{{yield this.edit this.getFocus this.lostFocus}}
  </div>
  */
  {
    "id": "3P7RDR3y",
    "block": "[[[11,0],[17,1],[16,\"tabindex\",[52,[30,0,[\"editable\"]],0]],[4,[38,1],[\"mousedown\",[30,0,[\"mouseDown\"]]],null],[4,[38,1],[\"mouseup\",[30,0,[\"mouseUp\"]]],null],[4,[38,1],[\"focusin\",[30,0,[\"focusIn\"]]],null],[12],[1,\"\\n\\t\"],[18,2,[[30,0,[\"edit\"]],[30,0,[\"getFocus\"]],[30,0,[\"lostFocus\"]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"if\",\"on\",\"yield\"]]",
    "moduleName": "client-base/components/cb-table/cb-editable-text-cell/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});