define("client-base/components/cb-slider/component", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/service", "@ember/object", "@ember/utils", "tracked-built-ins"], function (_exports, _component, _tracking, _service, _object, _utils, _trackedBuiltIns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SliderType = _exports.KnobType = void 0;

  var _class, _descriptor, _descriptor2, _class3, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  class Tick {
    constructor(text, posTick) {
      let anchor = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'middle';
      let posText = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : posTick;
      this.text = text;
      this.posTick = posTick;
      this.anchor = anchor;
      this.posText = posText;
    }

  }

  const KnobType = {
    SINGLE: 'SINGLE',
    UP: 'UP',
    DOWN: 'DOWN'
  };
  _exports.KnobType = KnobType;
  const SliderType = {
    SINGLE: 'Ein Wert',
    RANGE: 'Bereich',
    MULTIPLE: 'Mehrere Werte'
  };
  _exports.SliderType = SliderType;
  let Knob = (_class = class Knob {
    constructor(knobType, x, value) {
      _defineProperty(this, "knobType", void 0);

      _initializerDefineProperty(this, "x", _descriptor, this);

      _initializerDefineProperty(this, "value", _descriptor2, this);

      this.knobType = knobType;
      this.x = x;
      this.value = value;
    }

    setParameter(x, value) {
      this.x = x;
      this.value = value;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "x", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  /**
   * @class CbSliderComponent
   * @param {Number} [min=0]
   * @param {Number} [max=100]
   * @param {Number} [step=1]
   * @param {Number} [minRangeDistance=0] minimal erlaubte Distanz, wenn ein Bereich eingestellt werden soll, sollte sinnvollerweise ein vielfaches von step sein
   * @param {String} [unit='%']
   * @param {Map<KnobType, Number>} knobValues
   * @param {SliderType} [sliderType=SliderType.SINGLE]
   * @param {Function} onChange
   */

  let CbSliderComponent = (_class3 = class CbSliderComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "formatter", _descriptor3, this);

      _initializerDefineProperty(this, "element", _descriptor4, this);

      _defineProperty(this, "knobRadius", 15);

      _defineProperty(this, "SliderType", SliderType);

      _defineProperty(this, "KnobType", KnobType);

      _initializerDefineProperty(this, "knobs", _descriptor5, this);

      _initializerDefineProperty(this, "currentKnob", _descriptor6, this);

      _defineProperty(this, "correctTextPosition", void 0);

      _initializerDefineProperty(this, "moveAllowed", _descriptor7, this);
    }

    get knobDiameter() {
      return this.knobRadius * 2;
    }

    get ticks() {
      let ticks = [];
      ticks.pushObject(new Tick(this.formatter.number(this.min, {
        unit: this.unit
      }), this.calcValueToX(this.min), 'start', '0'));
      ticks.pushObject(new Tick(this.formatter.number(this.max, {
        unit: this.unit
      }), this.calcValueToX(this.max), 'end', '100'));
      return ticks;
    }

    setKnob(knobType, x, value) {
      let knob = null;

      if (this.knobs.has(knobType)) {
        knob = this.knobs.get(knobType);
        knob.setParameter(x, value);
      } else {
        knob = new Knob(knobType, x, value);
        this.knobs.set(knobType, knob);
      }

      return knob;
    }

    get sliderType() {
      return this.args.sliderType ?? SliderType.SINGLE;
    }

    get value() {
      return this._valueInternal ?? this.args.value;
    }

    set value(value) {
      this._valueInternal = value;
    }

    get min() {
      return this.args.min ?? 0;
    }

    get max() {
      return this.args.max ?? 100;
    }

    get minRangeDistance() {
      return this.args.minRangeDistance ?? 0;
    }

    get diff() {
      return Math.abs(this.max - this.min);
    }

    get step() {
      return this.args.step ?? 1;
    }

    get unit() {
      return this.args.unit ?? '%';
    }

    get x() {
      if ((0, _utils.isPresent)(this.value)) return this.calcValueToX(this.value);
      return null;
    }

    get showKnobSingle() {
      return this.knobs.has(KnobType.SINGLE);
    }

    get showKnobRange() {
      return this.sliderType === SliderType.RANGE && this.knobs.has(KnobType.UP) && this.knobs.has(KnobType.DOWN);
    }
    /**
     * wenn bei einer Range die anzuzeigenden Werte identisch sind, zeigen wir nur einen Knopf
     */


    get showRangeSingleValue() {
      return this.showKnobRange && this.formatter.number(this.knobs.get(KnobType.UP).value).toString() === this.formatter.number(this.knobs.get(KnobType.DOWN).value).toString();
    }
    /**
     * der eingegebene Wert darf nicht beliebig genau sein, sondern muss die Schrittweite beachten
     * @param {number} value der zu rundende Wert
     * @returns Wert auf step gerundet
     */


    roundValue(value) {
      let step = this.step; // bei einer Schrittweite von 0 gibt es nichts zu runden

      if (step === 0) return value; // wir bestimmen zuerst die Anzahl der Schritte vor unserem Wert

      let stepsNeeded = Math.floor(value / step); // anschließend bestimmen, ob wir an dem Schritt vor oder hinter uns näher dran sind

      if (step / 2 <= value - step * stepsNeeded) stepsNeeded += 1;
      return stepsNeeded * step;
    }

    calcXToValue(x) {
      let value = 0;

      if (this.element) {
        let width = this.element.offsetWidth;
        value = this.roundValue((x - this.knobRadius) / (width - 2 * this.knobRadius) * this.diff + this.min);
        if (value < this.min) value = this.min;else if (value > this.max) value = this.max;
      }

      return {
        value,
        // wenn wir value gerundet haben, müssen wir x korrigieren
        x: this.calcValueToX(value)
      };
    }

    calcValueToX(value) {
      let x = 0;

      if (this.element) {
        let width = this.element.offsetWidth;
        x = (value - this.min) / this.diff * (width - 2 * this.knobRadius) + this.knobRadius;
        if (x < this.knobRadius) x = this.knobRadius;else if (x > width - this.knobRadius) x = width - this.knobRadius; // console.log('===> cb-slider.calcValueToX', {width, x, xPercent: x / width * 100, value, min: this.min, max: this.max, diff: this.diff, knobRadius: this.knobRadius});

        x = x / width * 100;
      }

      return x;
    }

    updateKnobValues() {
      this.knobs.clear();

      for (let [knobType, value] of this.args.knobValues) {
        this.setKnob(knobType, this.calcValueToX(value), value);
      }
    }

    updateSliderType() {
      switch (this.sliderType) {
        case SliderType.SINGLE:
          {
            let newValue = null;

            if (this.knobs.has(KnobType.DOWN) && this.knobs.has(KnobType.UP)) {
              newValue = this.roundValue((this.knobs.get(KnobType.DOWN).value + this.knobs.get(KnobType.UP).value) / 2);
            } else if (this.knobs.has(KnobType.DOWN)) {
              newValue = this.knobs.get(KnobType.DOWN).value;
            } else if (this.knobs.has(KnobType.UP)) {
              newValue = this.knobs.get(KnobType.UP).value;
            }

            if (newValue !== null) {
              this.knobs.clear();
              this.setKnob(KnobType.SINGLE, this.calcValueToX(newValue), newValue);
              this.changeValue();
            }

            break;
          }

        default:
          break;
      }
    }

    insertSlider() {
      this.element = document.querySelector('.cb-slider'); // we can't call this method in the constructor, because we need element.offsetWidth for the calculation

      this.updateKnobValues();
    }

    changeValue() {
      let knobValues = new Map();

      for (let [knobType, knob] of this.knobs) {
        knobValues.set(knobType, knob.value);
      }

      this.args.onChange(knobValues);
    }

    correctKnob(baseKnob, moveableKnob) {
      if (baseKnob && moveableKnob) {
        let {
          minRangeDistance,
          max,
          min
        } = this,
            baseValue = baseKnob.value;

        if ( // Fehler 1: Abstand zwischen Knöpfen kleiner als minimal erlaubte Distanz
        Math.abs(baseValue - moveableKnob.value) <= minRangeDistance // Fehler 2a: DOWN-Knopf und UP-Knopf vertauscht, DOWN ist Basis
        || baseKnob.knobType === KnobType.DOWN && moveableKnob.knobType === KnobType.UP && baseKnob.value > moveableKnob.value // Fehler 2b: DOWN-Knopf und UP-Knopf vertauscht, UP ist Basis
        || baseKnob.knobType === KnobType.UP && moveableKnob.knobType === KnobType.DOWN && baseKnob.value < moveableKnob.value) {
          let moveableValue = moveableKnob.knobType === KnobType.DOWN ? baseValue - minRangeDistance : baseValue + minRangeDistance; // console.log('===> cb-slider.correctKnob 1', {baseValue, moveableValue, minRangeDistance, min, max});
          // if the moveable knob can't move, the other one must move

          if (moveableValue < min) {
            moveableValue = min;
            baseValue = min + minRangeDistance;
          } else if (moveableValue > max) {
            moveableValue = max;
            baseValue = max - minRangeDistance;
          } // console.log('===> cb-slider.correctKnob', {baseKnob, moveableKnob, baseValue, moveableValue});


          if (baseValue !== baseKnob.value) baseKnob.setParameter(this.calcValueToX(baseValue), baseValue);
          moveableKnob.setParameter(this.calcValueToX(moveableValue), moveableValue);
        }
      }
    }

    getOtherKnob(baseKnob) {
      if (this.sliderType === SliderType.RANGE && this.knobs.has(KnobType.DOWN) && this.knobs.has(KnobType.UP)) {
        let otherKnobType = baseKnob.knobType === KnobType.DOWN ? KnobType.UP : KnobType.DOWN;
        return this.knobs.get(otherKnobType);
      }

      return null;
    }

    /**
     * TouchEvent does not have offsetX and offsetY like the MouseEvent,
     * so we need to calculate it ourself
     * https://stackoverflow.com/a/43972308/2563573
     * 
     * @param {TouchEvent} event the event for which we need the offset
     * @param {HTMLElement} parent the element receiving the touch event - normally this.element
     */
    getOffsetForTouch(event, parent) {
      var position = {
        offsetX: event.targetTouches ? event.targetTouches[0].pageX : event.clientX,
        offsetY: event.targetTouches ? event.targetTouches[0].pageY : event.clientY
      };

      while (parent.offsetParent) {
        position.offsetX -= parent.offsetLeft - parent.scrollLeft;
        position.offsetY -= parent.offsetTop - parent.scrollTop;
        parent = parent.offsetParent;
      }

      return position;
    }

    slideStart(xOrg) {
      let {
        value,
        x
      } = this.calcXToValue(xOrg);

      if (this.sliderType === SliderType.SINGLE || this.knobs.size === 0) {
        this.currentKnob = this.setKnob(KnobType.SINGLE, x, value);
      } else if (this.sliderType === SliderType.RANGE) {
        // in den Knöpfen sind die x-Positionen als Prozente angegeben, also müssen wir für den Vergleich in Prozent umrechnen
        let xOrgPercent = xOrg / this.element.offsetWidth * 100;
        let otherKnob = null;

        if (this.knobs.size === 1) {
          let oldKnob = this.knobs.get(KnobType.SINGLE); // wenn die aktuelle x-Postion kleiner als die x-Position des alten Knopfes ist, wird der alte Knopf zum Up

          if (xOrgPercent < oldKnob.x) {
            this.currentKnob = this.setKnob(KnobType.DOWN, x, value);
            otherKnob = this.setKnob(KnobType.UP, oldKnob.x, oldKnob.value);
          } else {
            otherKnob = this.setKnob(KnobType.DOWN, oldKnob.x, oldKnob.value);
            this.currentKnob = this.setKnob(KnobType.UP, x, value);
          }

          this.knobs.delete(KnobType.SINGLE);
        } else {
          let knob = null;

          for (let knob2 of this.knobs.values()) {
            if (!knob || Math.abs(knob.x - xOrgPercent) > Math.abs(knob2.x - xOrgPercent) || knob.value === knob2.value && (knob.knobType === KnobType.DOWN && knob.x < xOrgPercent || knob.knobType === KnobType.UP && knob.x > xOrgPercent)) {
              knob = knob2;
            }
          }

          otherKnob = this.getOtherKnob(knob);
          knob.setParameter(x, value);
          this.currentKnob = knob;
        } // console.log('===> cb-slider.slideStart', {xOrg, xOrgPercent, cX: this.currentKnob.x, cV: this.currentKnob.value, oX: otherKnob.x, oV: otherKnob.value});


        this.correctKnob(this.currentKnob, otherKnob);
      }

      this.moveAllowed = true;
    }

    slideMove(buttonDown, xOrg) {
      if (this.moveAllowed) {
        if (buttonDown && this.currentKnob) {
          let {
            value,
            x
          } = this.calcXToValue(xOrg);
          this.currentKnob.setParameter(x, value);

          if (this.sliderType === SliderType.RANGE && this.knobs.size > 1) {
            let otherKnob = this.getOtherKnob(this.currentKnob);
            this.correctKnob(this.currentKnob, otherKnob);
          }
        } else {
          this.slideEnd();
        }
      }
    }

    slideEnd() {
      this.moveAllowed = false;
      this.changeValue();
    }

    touchStart(event) {
      // console.log('===> cb-slider.touchStart', {event, offset: this.getOffsetForTouch(event, this.element)});
      this.slideStart(this.getOffsetForTouch(event, this.element).offsetX);
    }

    touchMove(event) {
      // console.log('===> cb-slider.touchMove', {event, offset: this.getOffsetForTouch(event, this.element)});
      this.slideMove(true, this.getOffsetForTouch(event, this.element).offsetX);
    }

    touchEnd() {
      // console.log('===> cb-slider.touchEnd', {event, offset: this.getOffsetForTouch(event, this.element)});
      this.slideEnd();
    }

    mouseDown(event) {
      if (event.buttons === 1) {
        this.slideStart(event.offsetX);
      }
    }

    mouseUp() {
      this.slideEnd();
    }

    mouseMove(event) {
      this.slideMove(event.buttons === 1, event.offsetX);
    }

  }, (_descriptor3 = _applyDecoratedDescriptor(_class3.prototype, "formatter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "element", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class3.prototype, "knobs", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new _trackedBuiltIns.TrackedMap();
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class3.prototype, "currentKnob", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class3.prototype, "updateKnobValues", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "updateKnobValues"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "updateSliderType", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "updateSliderType"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "insertSlider", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "insertSlider"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "changeValue", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "changeValue"), _class3.prototype), _descriptor7 = _applyDecoratedDescriptor(_class3.prototype, "moveAllowed", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class3.prototype, "touchStart", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "touchStart"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "touchMove", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "touchMove"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "touchEnd", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "touchEnd"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "mouseDown", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "mouseDown"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "mouseUp", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "mouseUp"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "mouseMove", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "mouseMove"), _class3.prototype)), _class3);
  _exports.default = CbSliderComponent;
});