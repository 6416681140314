define("ember-bootstrap-changeset-validations/components/bs-form", ["exports", "@ember/debug", "ember-bootstrap/components/bs-form"], function (_exports, _debug, _bsForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class BsFormWithChangesetValidationsSupport extends _bsForm.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, '__ember-bootstrap_subclass', true);
    }
    get hasValidator() {
      return typeof this.model?.validate === 'function';
    }
    async validate(model) {
      let m = model;
      (false && !(m && typeof m.validate === 'function') && (0, _debug.assert)('Model must be a Changeset instance', m && typeof m.validate === 'function'));
      await m.validate();
      if (!model.get('isValid')) {
        throw new Error();
      }
    }
  }
  _exports.default = BsFormWithChangesetValidationsSupport;
});