define("client-base/components/cb-list/list-item/component", ["exports", "@glimmer/component", "@ember/object", "@ember/utils"], function (_exports, _component, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  /**
   * @param {Object} item
   * @param {Object} selectedItem
   * @param {Object} secondItem
   * @param {function} onSelect
   * @param {string} name an optional name that is passed from CbList and can be used in assert or debug statements
   */
  let CbListItemComponent = (_class = class CbListItemComponent extends _component.default {
    compare(item1, item2) {
      let equal = item1 === item2;

      if (!equal && !(0, _utils.isNone)(item1) && !(0, _utils.isNone)(item2)) {
        /*
         * in some cases the equality test returns false, though the items are equal, so we need to compare the id
         * but we also like to support primitives, for other objects we should consider a compare-function
         * .hasOwnProperty('id') does not work, because the items with the failing equality test, do not have a property 'id'
         */
        let idItem1 = item1.isDestroyed || item1.isDestroying ? undefined : item1.get('id');
        let idItem2 = item2.isDestroyed || item2.isDestroying ? undefined : item2.get('id');

        if (idItem1 !== undefined && idItem2 !== undefined) {
          equal = idItem1 === idItem2;
        }
      }

      return equal;
    }

    get selected() {
      return this.compare(this.args.item, this.args.selectedItem);
    }

    get second() {
      return this.compare(this.args.item, this.args.secondItem);
    }

    click(event) {
      this.args.onSelect(this.args.item); // especially for nested lists we need to stop the event, otherwise the surrounding list would get a select event

      event.stopPropagation();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "click", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "click"), _class.prototype)), _class);
  _exports.default = CbListItemComponent;
});