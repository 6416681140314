define("client-base/components/cb-progress-bar/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes class="cb-progress-bar {{if this.small "cb-progress-bar-small"}}">
  	{{#if this.showFirstBar}}
  		<div class="first-bar" style={{this.styleFirstBar}}/>
  	{{/if}}
  	{{#if this.showSecondBar}}
  		<div class="second-bar" style={{this.styleSecondBar}}/>
  	{{/if}}
  	{{#if this.showText}}
  		<div class="text">{{this.text}}</div>
  	{{/if}}
  </div>
  */
  {
    "id": "VMNsBdED",
    "block": "[[[11,0],[17,1],[16,0,[29,[\"cb-progress-bar \",[52,[30,0,[\"small\"]],\"cb-progress-bar-small\"]]]],[12],[1,\"\\n\"],[41,[30,0,[\"showFirstBar\"]],[[[1,\"\\t\\t\"],[10,0],[14,0,\"first-bar\"],[15,5,[30,0,[\"styleFirstBar\"]]],[12],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"showSecondBar\"]],[[[1,\"\\t\\t\"],[10,0],[14,0,\"second-bar\"],[15,5,[30,0,[\"styleSecondBar\"]]],[12],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"showText\"]],[[[1,\"\\t\\t\"],[10,0],[14,0,\"text\"],[12],[1,[30,0,[\"text\"]]],[13],[1,\"\\n\"]],[]],null],[13]],[\"&attrs\"],false,[\"if\"]]",
    "moduleName": "client-base/components/cb-progress-bar/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});