define("client-base/components/cb-screenshot/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <a href="#" ...attributes {{on "click" this.saveScreenshot}}>
  	<FaIcon class="symbol" @icon="image" @pull="right" @prefix="fas"/>
  </a>
  */
  {
    "id": "EkFX89hE",
    "block": "[[[11,3],[24,6,\"#\"],[17,1],[4,[38,0],[\"click\",[30,0,[\"saveScreenshot\"]]],null],[12],[1,\"\\n\\t\"],[8,[39,1],[[24,0,\"symbol\"]],[[\"@icon\",\"@pull\",\"@prefix\"],[\"image\",\"right\",\"fas\"]],null],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"on\",\"fa-icon\"]]",
    "moduleName": "client-base/components/cb-screenshot/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});